exports.components = {
  "component---src-containers-404-404-jsx": () => import("./../../../src/containers/404/404.jsx" /* webpackChunkName: "component---src-containers-404-404-jsx" */),
  "component---src-containers-account-auth-sign-in-sign-in-page-jsx": () => import("./../../../src/containers/account/auth/sign-in/SignInPage.jsx" /* webpackChunkName: "component---src-containers-account-auth-sign-in-sign-in-page-jsx" */),
  "component---src-containers-cruise-details-cruise-details-jsx": () => import("./../../../src/containers/cruiseDetails/CruiseDetails.jsx" /* webpackChunkName: "component---src-containers-cruise-details-cruise-details-jsx" */),
  "component---src-containers-cruise-list-cruise-list-jsx": () => import("./../../../src/containers/cruiseList/CruiseList.jsx" /* webpackChunkName: "component---src-containers-cruise-list-cruise-list-jsx" */),
  "component---src-containers-customer-space-account-dossier-jsx": () => import("./../../../src/containers/customerSpace/AccountDossier.jsx" /* webpackChunkName: "component---src-containers-customer-space-account-dossier-jsx" */),
  "component---src-containers-customer-space-account-lead-jsx": () => import("./../../../src/containers/customerSpace/AccountLead.jsx" /* webpackChunkName: "component---src-containers-customer-space-account-lead-jsx" */),
  "component---src-containers-customer-space-customer-space-jsx": () => import("./../../../src/containers/customerSpace/CustomerSpace.jsx" /* webpackChunkName: "component---src-containers-customer-space-customer-space-jsx" */),
  "component---src-containers-devis-devis-jsx": () => import("./../../../src/containers/devis/Devis.jsx" /* webpackChunkName: "component---src-containers-devis-devis-jsx" */),
  "component---src-containers-devis-form-devis-form-jsx": () => import("./../../../src/containers/devisForm/DevisForm.jsx" /* webpackChunkName: "component---src-containers-devis-form-devis-form-jsx" */),
  "component---src-containers-generic-page-generic-page-jsx": () => import("./../../../src/containers/genericPage/GenericPage.jsx" /* webpackChunkName: "component---src-containers-generic-page-generic-page-jsx" */),
  "component---src-containers-home-home-jsx": () => import("./../../../src/containers/home/Home.jsx" /* webpackChunkName: "component---src-containers-home-home-jsx" */),
  "component---src-containers-payment-by-friend-payment-by-friend-jsx": () => import("./../../../src/containers/paymentByFriend/PaymentByFriend.jsx" /* webpackChunkName: "component---src-containers-payment-by-friend-payment-by-friend-jsx" */),
  "component---src-containers-post-payment-payment-result-jsx": () => import("./../../../src/containers/postPayment/PaymentResult.jsx" /* webpackChunkName: "component---src-containers-post-payment-payment-result-jsx" */),
  "component---src-containers-promotions-promotions-list-jsx": () => import("./../../../src/containers/promotions/PromotionsList.jsx" /* webpackChunkName: "component---src-containers-promotions-promotions-list-jsx" */),
  "component---src-containers-solde-solde-jsx": () => import("./../../../src/containers/solde/Solde.jsx" /* webpackChunkName: "component---src-containers-solde-solde-jsx" */),
  "component---src-containers-sqa-cruise-sqa-cruise-page-jsx": () => import("./../../../src/containers/sqaCruise/SqaCruisePage.jsx" /* webpackChunkName: "component---src-containers-sqa-cruise-sqa-cruise-page-jsx" */),
  "component---src-containers-zone-cruise-list-zone-cruise-list-jsx": () => import("./../../../src/containers/zoneCruiseList/ZoneCruiseList.jsx" /* webpackChunkName: "component---src-containers-zone-cruise-list-zone-cruise-list-jsx" */)
}

